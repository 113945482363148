@tailwind base;
@tailwind components;
@tailwind utilities;

/* Scrollbar css */
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #888;
}